<template>
	<div>
		<a-breadcrumb style="margin: 16px 0">
			当前位置：
			<a-breadcrumb-item>
				<router-link :to="{path:'/'}">首页</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				<router-link :to="{path:'/BiaoZhunIndex'}">职业技能标准</router-link>
			</a-breadcrumb-item>
			<a-breadcrumb-item>
				2018年以后颁布的职业标准
			</a-breadcrumb-item>
		</a-breadcrumb>
		<div :style="{ background: '#fff', padding: '24px', minHeight: '280px' }">
			<div id='search-box' class="ant-col-6">
				<a-input-search placeholder="请输入职业编码或标准名称" enter-button @search="onSearch" />
			</div>
			<a-table :columns="result_columns" :data-source="result_arr" :pagination="false" bordered rowKey='index'>
				<a slot="num" slot-scope="text,record,index"  target="_blank">{{result_arr.length-index}}</a>
				<a slot="code" slot-scope="text,record" :href="record.web" target="_blank" v-if="record.web">{{text}}</a>
				<span slot="code" slot-scope="text,record" v-else>{{text}}</span>
				<a slot="detail" slot-scope="text,record" :href="record.web" target="_blank" v-if="record.web">点击查看详细标准信息</a>

			</a-table>
		</div>
	</div>
</template>

<script>
	const columns = [{
			title: '序号',
			dataIndex: 'index',
			// key: 'index',
			scopedSlots: {
				customRender: 'num'
			}
//			customRender: (text, record,index) => {
//				
//				return index+1
//			}
		},
		{
			title: '职业编码',
			dataIndex: 'code',
			// key: 'code',
			scopedSlots: {
				customRender: "code"
			},
		},
		{
			title: '标准名称',
			dataIndex: 'name',
			// key: 'name'
		},

		{
			title: '颁布时间',
			dataIndex: 'date',
			// key: 'date',

		},
		{
			title: '类型',
			dataIndex: 'type',
			// key: 'type',

		},
		{
			title: '操作',
			dataIndex: 'detail',
			scopedSlots: {
				customRender: "detail"
			},
		}
	];
	import { message } from 'ant-design-vue';
//	import Utils from '@/utils.js'
	export default {
		name: 'BiaoZhun',
		data: function() {
			return {
				org_info: {},
				result_arr: [],
				searchString: '',
				columns,
				result_columns: columns
			}
		},
		methods: {
			info() {
				message.info('暂未开通');
			},
			filteredArticles: function(value) {
				
				this.result_arr = this.org_info
				var search = value;
				if(!search) {
					return;
				}
				search = search.trim();
				this.result_arr = this.result_arr.filter(function(item) {
					if(item.code.indexOf(search) != -1 || item.name.indexOf(search) != -1) {
						return item;
					}
				})
			},
			onSearch: function(value) {
				//						var txt=this.search_key;
				//						$("table tbody .table_data").hide().filter(":contains('" + txt + "')").show();
				this.filteredArticles(value)
			}
		},
		beforeDestroy: function() {

		},

		created: function() {
			this.$nextTick(() => {
				// 禁用右键
				document.oncontextmenu = new Function("event.returnValue=false");
				// 禁用选择
				document.onselectstart = new Function("event.returnValue=false");
			});
			var that = this;

			this.$http.get('/json/biaozhun250326.json').then(reponse => {
				let res = reponse.data
				that.org_info = res.biaozhun_list
				that.result_arr = res.biaozhun_list

			})
		}
	}
</script>
<style>
	#search-box {
		float: left;
		margin: 0px auto 16px;
		z-index: 100;
	}
</style>